<template>
  <div class="main">
    <!-- 申请内容 -->
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'apply',
  data() {
    return {
      render: 1,
    };
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';
.main {
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: $border-radius-base;
  background-color: $background-color-layout;
  .main-step {
    border-radius: $border-radius-base;
    background-color: $background-color-layout;
    /deep/ .flex-space-between {
      padding: $spacing-base $spacing-medium;
    }
    /deep/ .el-steps--horizontal {
      padding: $spacing-large 0 $spacing-large 0;
    }
  }
}
</style>
